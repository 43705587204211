// -----------------------------------------------------------------------------
// This file contains sidebar block styles.
// -----------------------------------------------------------------------------

.sidebar {
	position: fixed;
	top: 0;
	background: $primary-color;
	color: #fff;
	width: 300px;
	min-height: 100vh;
	z-index: 10;
	transition: .25s;
	display: none;

	@include phone() {
		height: 100%;
		max-height: 100%;
		overflow: auto;
		padding-bottom: 60px;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.user-panel {
		padding: 10px 30px;
	}

	.input-group {
		margin-bottom: 10px;
	}

	.form-control {
		background: rgba(#000, .05);
		border: 1px solid rgba(#000, .075);
		border-left: 0;
		color: #fff;
	}

	.input-group-icon {
		background: rgba(#000, .05);
		border: 1px solid rgba(#000, .075);
		border-right: 0;
		i {
			color: rgba(#fff, .8);
		}
	}

	input::-webkit-input-placeholder {
		color: rgba(#fff, .8);
	}
	input::-moz-placeholder{
		color: rgba(#fff, .8);
	}
	input:-moz-placeholder {
		color: rgba(#fff, .8);
	}
	input:-ms-input-placeholder{
		color: rgba(#fff, .8);
	}

	&-list {
		padding: 0 30px;
		max-height: 86vh;
		overflow: auto;
		-ms-overflow-style: none;
		list-style: none;
		transition: .25s;

		@include phone() {
			max-height: auto;
			height: auto;
			max-height: 100%;
			overflow: hidden;
			padding: 0px 10px;
		}

		li {
			margin-bottom: 0;
		}

		&-header {
			display: block;
			margin: 8px 0;
			padding-bottom: 8px;
			width: 100%;
			color: #fff;
			font-size: 13px;
			opacity: .75;
			border-bottom: 1px solid rgba(255,255,255,.15);
		}

		&-item {
			padding: 10px;
			height: 40px;
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 16px;
			white-space: nowrap;
			opacity: .75;
			border-left: 2px solid transparent;
			border-radius: $block-border-radius;
			transition: .6s;
			color: #fff;
			cursor: pointer;

			i:not(.pull-right) {
				width: 30px;
				margin-right: 10px;
				line-height: 0;
				font-size: 24px;
				text-align: center;
			}

			i.pull-right {
				position: relative;
				top: 1px;
				width: 15px;
				height: 15px;
				text-align: center;
				line-height: 15px;
				font-size: 12px;
				transition: .25s;
			}

			&:hover {
				text-decoration: none;
				box-shadow: 0 15px 30px rgba(0,0,0,.06);
				background: lighten($primary-color, 7%);
			}

			&:active,
			&:focus {
				text-decoration: none;
			}

			&.active {
				i.pull-right {
					transform: rotate(180deg);
				}
				border-left: 2px solid #fff;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

				& + ul {
					display: block;
				}
			}
		}

		ul {
			display: none;
			list-style: none;

			.sidebar-list-item {
				height: 35px;
				font-size: 14px;
			}
		}
	}

	@include phone() {
		width: 260px;
		display: none;
	}

	& ~ .header,
	& ~ .content,
	& ~ .footer {
		// transform: translate(0, 0);
		// transition: .25s;

		@include desktop() {
			padding-left: 325px;
		}

		@include desktop-small() {
			transform: translate(225px, 0);
			padding-left: 95px;
		}

		@include tablet() {
			transform: translate(225px, 0);
			padding-left: 95px;
		}

		@include phone() {
			transform: translate(0);
			padding-left: 15px;
		}
	}

	& ~ .content > .container-fluid {
		padding-left: 0 !important;
	}
}

.sidebar-collapse {
	width: auto;

	.logo {
		padding: 0;
		margin: 0;
		height: 60px;
		text-indent: -999px;

		img {
			margin: 0 auto;
			margin-top: 11px;
			width: 40px;
		}

		*:not(img) {
			display: none;
		}
	}

	.input-group {
		display: none;
	}

	.sidebar{

		&-list {
			padding: 0 8px;

			&-header {
				margin-top: 0;
				text-indent: -999px;
				line-height: 0;
			}

			&-item {
				cursor: pointer;
				opacity: 1;
				i {
					opacity: .75;	
				}
				i:not(.pull-right) {
					margin-right: 0;
				}

				i.pull-right {
					display: none;
				}

				span {
					position: absolute;
					left: 80%;
					padding: 9px 15px;
					visibility: hidden;
					opacity: 0;
					background: $primary-color;
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;
					font-size: 16px;
					z-index: 3;
					transition: .25s;
				}

				&:hover span,
				&.active span {
					visibility: visible;
					left: 100%;
					width: auto;
					opacity: 1;
					box-shadow: 0 15px 30px rgba(0,0,0,.06);
				}
			}

			ul {
				position: absolute;
				left: 100%;
				padding: 5px;
				margin-top: -1px;
				background: lighten($primary-color, 5%);
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
				z-index: 4;
			}
		}
	}

	@include phone() {
		transform: translate(-100%, 0);
	}

	& ~ .header,
	& ~ .content,
	& ~ .footer {
		transform: translate(0, 0);
		transition: .25s;

		@include desktop() {
			padding-left: 95px;
		}

		@include desktop-small() {
			padding-left: 95px;
		}

		@include tablet() {
			padding-left: 95px;
		}

		@include phone() {
			padding-left: 15px;
		}
	}
}


.no-scroll {
	position: relative;
	height: 100%;
	overflow: hidden;
}